import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Alert, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const RevGuidesMane: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Mane's Bulletin (Raids)</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_raid.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Reverse:1999 Mane's Bulletin (Raids)</h1>
          <h2>
            Everything you need to know about the Mane's Bulletin mode where you
            fight various bosses.
          </h2>
          <p>
            Last updated: <strong>18/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <SectionHeader title="Foreword" />
            <p>
              Special thanks to <strong>Gordan</strong> who worked tirelessly on
              this guide and <strong>Liriell</strong> who helped him.
            </p>

            <SectionHeader title="Mane's Bulletin" />
            <StaticImage
              src="../../../images/reverse/generic/boss_intro.webp"
              alt="Raid Boss"
            />
            <Alert variant="primary alert-red ">
              <p>
                Starting on 1.6, the total score gives a currency instead, that
                you can use to buy materials at the Mane's Bulletin shop
              </p>
            </Alert>
            <p>
              In Patch 1.4 “Prisoners of the Cave”, we are introduced to a new
              side-event that takes place within each update cycle. This is
              called <strong>“Mane’s Bulletin”</strong>, and it will serve as an
              additional layer to Reverse:1999’s endgame content alongside the
              biweekly Limbo rotation and the UTTU stages that appear during the
              second phase of each event.
            </p>
            <p>
              <strong>
                Mane’s Bulletin is a raid-boss event that pits a team of 4 of
                your characters against a big bad
              </strong>
              , providing you with various rewards depending on the total damage
              done over the course of the fight and, once you unlock a special
              difficulty on each boss, based on how much damage you do during
              said mode.
            </p>
            <p>
              As the team you’ll be fighting these bosses will{' '}
              <strong>consist of 4 characters</strong>, you have a lot more room
              to play around with team compositions. An extra character means an
              extra carry, utility or healer slot, and it also means one more
              AP, which ends up making a big difference.
            </p>
            <p>
              <strong>
                The side content runs for both the first and second phase of the
                patch version, with a new boss unlocking in week 2, 4 and 6, for
                a total of 3 bosses over the duration of the event
              </strong>
              . Mane’s Bulletin, like UTTU, will be a “permanent” game mode that
              appears during every new update.
            </p>
            <p>
              On each boss, your total damage dealt will be recorded,{' '}
              <strong>with a set of rewards at certain damage intervals</strong>
              . Furthermore, once you’ve beaten every difficulty of a boss, you
              will unlock their Endless mode. As the name implies, the boss has
              an infinite amount of HP on this difficulty, with this being
              mostly a stress test of how well you can deal with the mechanics
              of the boss while outputting as much damage as you can.
            </p>
            <p>
              The ranks for Endless mode range from C to SSS, with each rank
              offering a set of juicy rewards in the form of materials and shop
              currency.{' '}
              <strong>
                To note is that to get all the major materials from this
                difficulty, you only need to hit S-rank
              </strong>
              , not SSS-rank, as that is mostly just for people to flex their
              characters or skill at the game.
            </p>
            <ul>
              <li>
                <strong>C</strong> - 25,000
              </li>
              <li>
                <strong>C+</strong> - 50,000
              </li>
              <li>
                <strong>B</strong> - 120,000
              </li>
              <li>
                <strong>B+</strong> - 200,000
              </li>
              <li>
                <strong>A</strong> - 280,000
              </li>
              <li>
                <strong>A+</strong> - 360,000
              </li>
              <li>
                <strong>S</strong> - 450,000
              </li>
              <li>
                <strong>SS</strong> - 650,000
              </li>
              <li>
                <strong>SSS</strong> - 850,000
              </li>
            </ul>
            <p>
              In addition, while this guide offers a small team building guide
              that ‘ensures’ good Endless Mode tiers (assuming they are played
              correctly), there is no way for us to list all of the teams that
              function in this content, so they serve as more of a guideline
              than a strict limitation. We encourage you to analyze the Raid
              mechanics and conceptualize your own teams.
            </p>
            <p>
              With that out of the way, check out our dedicated guides for
              Various Mane Builetin bosses below!
            </p>
            <Row
              xs={1}
              md={2}
              lg={2}
              xxl={3}
              className="g-3 category-cards-container"
            >
              <CategoryCard
                title="Darkness of the Abyss"
                link="/re1999/guides/darkness-of-the-abyss"
                image={
                  <StaticImage
                    src="../../../images/reverse/categories/category_abyss.webp"
                    alt="Team Building"
                  />
                }
              />
              <CategoryCard
                title="Marsh Creation"
                link="/re1999/guides/marsh-creation"
                image={
                  <StaticImage
                    src="../../../images/reverse/categories/category_marsh.webp"
                    alt="Team Building"
                  />
                }
              />
              <CategoryCard
                title="Gold in the Cave"
                link="/re1999/guides/gold-in-the-cave"
                image={
                  <StaticImage
                    src="../../../images/reverse/categories/category_gold.webp"
                    alt="Team Building"
                  />
                }
              />
              <CategoryCard
                title="Hypothesis of Exhibition"
                link="/re1999/guides/hypothesis-of-exhibition"
                image={
                  <StaticImage
                    src="../../../images/reverse/categories/category_hypothesis.webp"
                    alt="Team Building"
                  />
                }
              />
              <CategoryCard
                title="Projection of Nightmares"
                link="/re1999/guides/projection-of-nightmares"
                image={
                  <StaticImage
                    src="../../../images/reverse/categories/category_projection.webp"
                    alt="Team Building"
                  />
                }
              />
              <CategoryCard
                title="Isle Cryptid"
                link="/re1999/guides/isle-cryptid"
                image={
                  <StaticImage
                    src="../../../images/reverse/categories/category_cryptid.webp"
                    alt="Team Building"
                  />
                }
              />
              <CategoryCard
                title="Echoes of Opera"
                link="/re1999/guides/echoes-of-opera"
                image={
                  <StaticImage
                    src="../../../images/reverse/categories/category_opera.webp"
                    alt="Team Building"
                  />
                }
                isNew
              />
            </Row>
            <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default RevGuidesMane;

export const Head: React.FC = () => (
  <Seo
    title="Mane's Bulletin (Raids) | Reverse: 1999 | Prydwen Institute"
    description="Everything you need to know about the Mane's Bulletin mode where you fight various bosses."
    game="reverse"
  />
);
